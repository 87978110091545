import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./contexts";
import './index.css';

/* == ant design == */

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import 'antd/dist/antd.css';

ReactDOM.render(
  <ContextProvider>
    <BrowserRouter
      basename={'/admin'}
    >
      <ConfigProvider
        locale={enUS}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </ContextProvider>,
  document.getElementById('root')
);
