import React, {useEffect, useState} from 'react';
import {getAllComplains} from '../services/complain';
import {complainStates} from "../constants";
import {getComplainStateTag} from '../utils/organizeData'

/* == antd == */

import { Card, Row, Col } from "antd";
import { Bar } from '@ant-design/charts';

export function DashboardPage() {

  // const admin = useContext(AccountContext);
  const [complains, setComplains] = useState<ComplainDto[]>([]);

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    await getAllComplains().then((res) => {
      const sortedComplains = res.sort((a, b) => new Date(b.complainAt).getTime() - new Date(a.complainAt).getTime());
      setComplains(sortedComplains);
    });
  }

  const provinceBarGraphDataMap = new Map();
  const stateDataMap = new Map();

  for (const complain of complains) {
    if (complain.province === 'cc' || complain.province === 'c') continue;
    if (provinceBarGraphDataMap.has(complain.province)) {
      const provinceCount = provinceBarGraphDataMap.get(complain.province);
      provinceBarGraphDataMap.set(complain.province, provinceCount + 1);
    } else {
      provinceBarGraphDataMap.set(complain.province, 1);
    }
  }

  for (const state of complainStates) {
    stateDataMap.set(state.eng, 0);
  }

  for (const complain of complains) {
    if (stateDataMap.has(complain.state)) {
      const stateCount = stateDataMap.get(complain.state);
      stateDataMap.set(complain.state, stateCount + 1);
    } else {
      stateDataMap.set(complain.state, 1);
    }
  }

  stateDataMap.set("isClaimable", (complains.filter(e => e.isClaimable)).length)

  const provinceBarGraphConfig = {
    data: Array.from(provinceBarGraphDataMap).map(e => {
      return {
        province: e[0],
        count: e[1]
      }
    }),
    xField: 'count',
    autoFit: true,
    yField: 'province',
    barBackground: { style: { fill: 'rgba(0,0,0,0.1)' } },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  }

  const stateCards = [];
  for (const state of Array.from(stateDataMap)) {
    const eng = state[0];
    const count = state[1];
    stateCards.push((
      <Col xs={24} sm={12} md={4} key={eng + count}>
        <Card title={getComplainStateTag(eng)}>
          {stateDataMap.get(eng) ? stateDataMap.get(eng) + '건' : 0 + '건' }
        </Card>
      </Col>
    ))
  }

  return (
    <div>
      <Row
        gutter={[8, 8]}
        style={{marginBottom: '20px'}}
      >
        {stateCards}
      </Row>
      <Row>
        <Col span={24}>
          <Card
            title={"지역별 민원 현황"}
            style={{height: '100vh'}}
          >
            <Bar {...provinceBarGraphConfig}
                 style={{
                   height:'90vh'
                 }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
