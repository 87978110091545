import {message} from "antd";
import {putComplainTowReceipt} from "../services/complain";
import {CDNUploader} from '@theswing/cdn';

export const uploadImg = async (complainId: string, imageNewName: string, receiptImageFile: any) => {

  const cdn = new CDNUploader('eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJzd2luZ19hZG1pbl91cGxvYWQiLCJpYXQiOjE2NTM1NTAzMjZ9.YtbXNXk_KRSbXBBAZUKINCJ7Uq8UnYlbNHXd3OAUA8WY5l6ewycrVi6Zuqxn2Fc_gM0hnsj5W7CEWlB9pKFpDbAwcTLhlAaL7sFCJTdUhvVHzplboYcdvBuKYAq2mj3C_-8XhQ_mdfjBfVDX_1kJCzhM0E1AF-VuAhRVFjuIxM9ZTfQb6djnOvgoWJnPNOROzKFdCtoFWcLyaUoCZuVrsgrj-k0gvEFzJN6eaUY8A2xBWOeGR0mhhFdTsAFX9KonscGS0QrJXhFdADcR5htm_JqY7HVEmyKWD5196860R3uiL8ZYC20vx6brU1WFE7dzXKfSeTw0k6M5r13KSFAYx8w3HwWQZXOXgw1pZl2J313mhbKDpMLLNvEcwP_hi905UnVs8p3zMMXoEtJjaoIB0uWjwdUZlTW4_1o4QSZKnhmLSzvgbEatE-ud9DDalQIWgWyadSgbrCkC65jSZpBVAxIOPSJk05DW8KqwG7xUNC5KAA3SV3UsZmPcSWXjYPhHJ8mz3iFi8x-T5PGZGjMqqoXHcelT24VjNwjM7TeVxTrEo61MmtrgwH4svG5uqseZSdUxLKCiwLaCkJ_-7Iqa7UTuI-n4e7Xek6Tnv5xHU8of3XODE1bm0GDj44nFxi04jCb12L8tthEfB-Mq5g2UCaP-tZ7WO2rkmqliU7OkbOE');


  try {
    const res = await cdn.upload(receiptImageFile, {
      filename: receiptImageFile.name,
      mime: receiptImageFile.type,
    });
    message.success('업로드 되었습니다');
    await putComplainTowReceipt(complainId, {
      towReceiptImage: res.url
    });
    message.success('영수증이 등록되었습니다');
    window.location.reload()
  } catch (err) {
    alert("영수증이 등록에 실패하였습니다" + err);
    window.location.reload()
  }
}