import React, {useEffect, useState} from 'react';
import {Input} from 'antd';
import {getAllComplains} from '../services/complain';
import {ComplainListTable} from "../components/tables/ComplainListTable";
import {AddComplainModal} from "../components/modals/AddComplainModal";

/* == antd == */

import { Card, Statistic, Row, Col, Radio, Divider, Button, Descriptions } from "antd";
import {PlusOutlined} from "@ant-design/icons";
const {Countdown} = Statistic;

type FilterCriteria = 'recent' | 'isClaimable' | 'null' | 'isTowed'

export function ComplainListPage() {

  // const admin = useContext(AccountContext);
  const [complains, setComplains] = useState<ComplainDto[]>([]);
  const [filteredComplains, setFilteredComplains] = useState<ComplainDto[]>([]);
  const [filterBy, setFilterBy] = useState<FilterCriteria>('null');
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState('');

  const handleIsEditModalVisible = () => {
    setIsAddModalVisible(!isAddModalVisible);
  }

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    filterComplains();
  }, [filterBy, searchStr]);

  async function bootstrap() {
    await getAllComplains().then((res) => {
      const sortedComplains = res.sort((a, b) => new Date(b.complainAt).getTime() - new Date(a.complainAt).getTime());
      setComplains(sortedComplains);
      setFilteredComplains(sortedComplains);
    });
  }

  const deadline = Date.now() + 1000 * 60 * 3;

  function onFinish() {
    window.location.reload()
  }

  const filterComplains = () => {
    const searchFilterComplains = searchStr === ''
      ? complains
      : complains.filter(e => e.shortImei.indexOf(searchStr.toUpperCase()) !== -1);


    if (filterBy === 'recent') {
      setFilteredComplains(searchFilterComplains.filter(e => new Date().getTime() - e.complainAt <= 300000));
    } else if (filterBy === 'isClaimable') {
      setFilteredComplains(searchFilterComplains.filter(e => e.isClaimable));
    } else if (filterBy === 'isTowed') {
      setFilteredComplains(searchFilterComplains.filter(e => e.state === 'TOWED'));
    } else {
      setFilteredComplains(searchFilterComplains)
    }
  }

  const onFilterRadioChange = (e: any) => {
    setFilterBy(e.target.value)
  }

  return (
    <div>
      <Card
        style={{marginBottom: '20px', textAlign: 'center', padding: '5px'}}
      >
        <Row align={'middle'} justify={'center'} gutter={[8, 8]}>
          <Col
            sm={24}
            md={8}
          >
            <Countdown title="자동으로 새로고침 됩니다" value={deadline} onFinish={onFinish}/>
          </Col>
          <Divider/>
          <Col
            xs={24}
            sm={24}
            md={16}
          >
            <Radio.Group value={filterBy} buttonStyle="solid" onChange={onFilterRadioChange} style={{width: '100%'}}>
              <Row gutter={[4, 4]}>
                <Col xs={24} sm={24} md={12}><Radio.Button value="recent" style={{width: '100%', textAlign: 'center'}}>최근5분</Radio.Button></Col>
                <Col xs={24} sm={24} md={12}><Radio.Button value="isTowed" style={{width: '100%', textAlign: 'center'}}>견인됨</Radio.Button></Col>
                <Col xs={24} sm={24} md={12}><Radio.Button value="isClaimable" style={{width: '100%', textAlign: 'center'}}>이의제기건</Radio.Button></Col>
                <Col xs={24} sm={24} md={12}><Radio.Button value="null" style={{width: '100%', textAlign: 'center'}}>초기화</Radio.Button></Col>
              </Row>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Input
              placeholder={'SIMEI를 입력해주세요'}
              value={searchStr}
              onChange={e => setSearchStr(e.target.value)}
            />
          </Col>
        </Row>
      </Card>
      <AddComplainModal isVisible={isAddModalVisible} setIsVisible={setIsAddModalVisible}/>
      <Descriptions
        title={`민원목록 (${filteredComplains.length})`}
        extra={<Button icon={<PlusOutlined />} onClick={handleIsEditModalVisible}/>}
      />
      <ComplainListTable
        complains={filteredComplains}
      />
    </div>
  );
}
