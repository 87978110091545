import React from 'react';
import {Tag, Descriptions} from 'antd';
import {getDateTime} from "../../utils/organizeData";
import {getDateTimeLong, getComplainStateTag} from "../../utils/organizeData";
import { ADMIN_WEB_URL } from '../../constants';

export function ComplainDetailDescription({complain}: { complain: ComplainDto }) {

  let complainStateTag = getComplainStateTag(complain.state);

  return (
    <>
      <Descriptions
        title="민원세부정보"
        layout="vertical"
        bordered={true}
        column={{xs: 1, sm: 2, lg: 4}}
        // extra={<Button type="primary" shape="circle" icon={<EditOutlined />} onClick={handelIsEditModalVisible}/>}
        size={'middle'}
        style={{marginBottom: '20px'}}
      >
        <Descriptions.Item label="민원번호">{complain.id}</Descriptions.Item>
        <Descriptions.Item label="민원 날짜" span={2}>{getDateTimeLong(complain.complainAt)}</Descriptions.Item>
        <Descriptions.Item label="민원 경로">{complain.complainFrom}</Descriptions.Item>
        <Descriptions.Item label="민원지역">{complain.province}</Descriptions.Item>
        <Descriptions.Item label="민원세부 주소">{complain.address}</Descriptions.Item>
        <Descriptions.Item label="민원 사유" contentStyle={{
          wordBreak: 'break-all'
        }}>{complain.reason.split('.')}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={'민원라이드 정보'}
        layout="vertical"
        bordered={true}
        column={{xs: 1, sm: 2, lg: 3}}
        size={'middle'}
        style={{
          marginTop: '10px'
        }}
        // extra={<Button type="primary" shape="circle" icon={<BulbOutlined />} onClick={handleImposeWarning}/>}
      >
        <Descriptions.Item label="킥보드 번호">{complain.shortImei ?
          <a 
            href={`${ADMIN_WEB_URL}/#/app/scoot/${complain.imei}`} 
            target={"_blank"}
          >
            {complain.shortImei}
          </a>
          : "없음"}</Descriptions.Item>
        <Descriptions.Item label="마지막 라이더">{
          complain.recentUserName ? 
          <a 
            href={`${ADMIN_WEB_URL}/#/app/user/${complain.recentUserPhoneNumber}`} 
            target={"_blank"}
          >
            {complain.recentUserName}
            </a>
            : "없음"
        }</Descriptions.Item>
        <Descriptions.Item label="마지막 라이더 번호">{complain.recentUserPhoneNumber ?? "없음"}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={'민원 상태'}
        layout="vertical"
        bordered={true}
        column={{ xs: 1, sm: 1, lg: 2}}
        size={'middle'}
        style={{
          marginTop: '10px'
        }}
      >
        <Descriptions.Item label="상태">{complainStateTag}</Descriptions.Item>
        <Descriptions.Item label="견인여부">{complain.isTowed ? <Tag color="error">견인됨</Tag> : <Tag color="success">견인전</Tag>}</Descriptions.Item>
        <Descriptions.Item label="견인시간">{complain.isTowed ? getDateTime(complain.towedAt) : '-'}</Descriptions.Item>
        <Descriptions.Item label="이의제기건">{complain.isClaimable ? <Tag color="error">이의건</Tag> : "-"}</Descriptions.Item>
        {/*<Descriptions.Item label="견인금액">{complain.towFine}</Descriptions.Item>*/}
      </Descriptions>
    </>

  );
}
