import React from 'react';

import {
  DashboardOutlined,
  AlertOutlined,
} from '@ant-design/icons';

export const routers = [
  {
    key: 'dashboard',
    path: '/dashboard',
    name: '대쉬보드',
    icon: <DashboardOutlined/>
  },
  {
    key: 'complain',
    path: '/complain',
    name: '민원관리',
    icon: <AlertOutlined />
  }
];