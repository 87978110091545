import React, {FC, useState} from 'react';
import {BaseModal} from '../bases/BaseModal';
import { DatePicker, Form, Row, Col, Input} from 'antd';
import {putComplainIsTowed, putComplainState} from "../../services/complain";
import {useParams} from "react-router-dom";
import moment from 'moment';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editComplain?: ComplainDto | null;
}

const EditTowModal: FC<Props> = ({isVisible, setIsVisible, editComplain}) => {

  const [formValues, setFormValues] = useState<{towedAt: number} | null>(null);
  const [formInstance] = Form.useForm();
  const params: { id: string } = useParams();

  const onSubmitForm = async (formValues: {towedAt: number}) => {
    const seconds = moment(formValues.towedAt).valueOf();
    console.log(seconds);
    const updateComplainDto: {towedAt: number} = {
      towedAt: formValues.towedAt.valueOf()
    };
    putComplainIsTowed(params.id, updateComplainDto).then(e => {
      console.log(e);
      putComplainState(params.id, {state: "TOWED"}).then(l => {
        window.location.reload()
      })
    })
  }

  const submitModal = async () => {
    if (formValues) { // 변경이 있는 경우
      formInstance.submit();
    } else { // 변경이 없는 경우
      setIsVisible(false);
    }
  }

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={submitModal}
      handleCancel={() => {
        setIsVisible(false);
      }}
      headerTitle={'견인건'}
      width={'70%'}
    >
      <Form
        name="editComplainForm"
        onValuesChange={(changedVal, allVal) => {
          setFormValues(allVal);
        }}
        form={formInstance}
        onFinish={onSubmitForm}
      >
        <Row gutter={[16, 32]}
             justify={'center'}
        >
          <Col xs={24} sm={24} md={24}>
            <Form.Item label="민원처리 일시" name="towedAt">
              <Input style={{width: '100%'}} placeholder={"YYYY-MM-DD HH:mm:SS 형식 지켜주세요"}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
};

export default EditTowModal;
