import React from 'react';
import {routers} from './routers';
import {Switch, Route, useLocation} from "react-router-dom";
import './App.css';
import {DashboardPage} from "./pages/Dashboard";
import {ComplainListPage} from "./pages/ComplainList";
import {ComplainPage} from "./pages/Complain";
import logo from './images/logo.png'

/* == ant design == */
import {Layout, Menu} from "antd";
import "antd/dist/antd.css";

const {Header, Content, Sider} = Layout;

function App() {

  const location = useLocation();
  // const account = useContext(AccountContext);

  const menuItems = routers.map(e => {
    return (
      <Menu.Item
        key={e.key}
        icon={e.icon}
        onClick={() => {
          window.location.href = e.path
        }}
      >
        {e.name}
      </Menu.Item>
    )
  })

  // if (account) {
  return (
    <Layout
      style={{minHeight: "100vh"}}
      hasSider={true}
    >
      <Sider
        collapsible={true}
        collapsedWidth={50}
        width={150}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <img
          alt={"logo"}
          src={logo}
          className="logo"
          style={{
            display: 'block',
            margin: '10px auto'
          }}
        />
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname.slice(1)]}>
          {menuItems}
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{padding: 0}}
        />
        <Content style={{margin: "24px 16px 0"}}>
          <div
            className="site-layout-background"
            style={{padding: 12, minHeight: '100%'}}
          >
            <Route exact path={"/dashboard"}>
              <DashboardPage/>
            </Route>
            <Switch>
              <Route exact path={"/complain"}>
                <ComplainListPage/>
              </Route>
              <Route path={"/complain/:id"}>
                <ComplainPage/>
              </Route>
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
