import React, { useEffect, useState } from "react";
import { complainStates } from "../constants";
import {
  getComplain,
  putComplainClaimable,
  putComplainState,
} from "../services/complain";
import { useParams } from "react-router-dom";
import { uploadImg } from "../utils/uploadImg";
import { getImageName } from "../utils/generateData";
import { ComplainDetailDescription } from "../components/descriptions/ComplainDetailDescription";

/* == antd == */

import { Card, Row, Col, Button, Image, Upload, Radio } from "antd";
import EditTowedModal from "../components/modals/EditTowModal";

export function ComplainPage() {
  // const admin = useContext(AccountContext);
  const [complain, setComplain] = useState<ComplainDto | null>(null);
  const [complainImages, setComplainImages] = useState<string[]>([]);
  const [isTowEditModalVisible, setIsTowEditModalVisible] =
    useState<boolean>(false);
  const params: { id: string } = useParams();
  const [receiptImageFile, setReceiptImageFile] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    imageUrlParser();
  }, [complain]);

  async function bootstrap() {
    await getComplain(params.id).then(setComplain);
  }

  function imageUrlParser() {
    if (complain) {
      if (complain.complainImages) {
        complain.complainImages.map((e) => {
          if (!e.includes("2022/")) {
            const parseUrl = e.replace("2022", "2022/");
            return setComplainImages((prev) => [...prev, parseUrl]);
          }
          return setComplainImages((prev) => [...prev, e]);
        });
      }
    }
  }

  const handlelIsTowEditModalVisible = () => {
    setIsTowEditModalVisible(!isTowEditModalVisible);
  };

  const complainStateRadioButtons = complainStates.map((e, i) => {
    return (
      <Col xs={24} sm={24} md={12} key={"complainStateRadioButton-" + i}>
        <Radio.Button
          value={e.eng}
          style={{ width: "100%", textAlign: "center" }}
        >
          {e.kor}
        </Radio.Button>
      </Col>
    );
  });

  const handleComplainClaimable = async () => {
    if (window.confirm("이의제기건으로 등록하시겠습니까?")) {
      try {
        await putComplainClaimable(params.id, {
          isClaimable: true,
        }).then((e) => {
          alert("이의제기건으로 등록 되었습니다");
          window.location.reload();
        });
      } catch (err) {
        alert("이의제기건 등록이 실패하였습니다" + err);
        window.location.reload();
      }
    }
  };

  const handleComplainStateChange = async (e: any) => {
    try {
      await putComplainState(params.id, {
        state: e.target.value,
      }).then((e) => {
        window.location.reload();
      });
    } catch (err) {
      alert("나중에 다시 시도해주세요" + err);
      window.location.reload();
    }
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      setReceiptImageFile(file);
      return false;
    },
    receiptImageFile,
  };

  const handleImageUpload = () => {
    setImageUploading(true);
    if (receiptImageFile) {
      const imageNewName = getImageName();
      uploadImg(params.id, imageNewName, receiptImageFile);
    }
    setReceiptImageFile(null);
    setImageUploading(false);
  };

  if (complain) {
    let complainImageTags = [];
    if (complainImages.length !== 0) {
      complainImageTags = complainImages.map((e, i) => (
        <Col key={`complain-${i}`} sm={24} md={24 / complainImages.length}>
          <Card
            style={{ width: "100%" }}
            cover={<Image alt={`complainImage-${i}`} src={e} width={"70%"} />}
            title={`민원사진 ${i + 1}`}
          />
        </Col>
      ));
    } else {
      //사진이 없는 경우
      complainImageTags.push(
        <Card
          style={{ width: "100%" }}
          cover={<Image alt="example" src={complainImages[0]} />}
          title="민원사진"
        />
      );
    }

    return (
      <div>
        <EditTowedModal
          isVisible={isTowEditModalVisible}
          setIsVisible={setIsTowEditModalVisible}
        />
        <ComplainDetailDescription complain={complain} />
        <Card style={{ marginBottom: "20px" }}>
          <Upload {...uploadProps} style={{ width: "100%" }}>
            <Button block={true} type={"primary"}>
              견인 영수증 등록하기
            </Button>
          </Upload>
          <Button
            type="primary"
            onClick={handleImageUpload}
            disabled={receiptImageFile == null}
            loading={imageUploading}
            style={{ marginTop: 16 }}
          >
            {imageUploading ? "Uploading" : "Start Upload"}
          </Button>
        </Card>
        <Card style={{ marginBottom: "20px" }}>
          <Radio.Group
            defaultValue={complain.state}
            buttonStyle="solid"
            style={{ width: "100%" }}
            onChange={handleComplainStateChange}
          >
            <Row gutter={[8, 8]}>{complainStateRadioButtons}</Row>
          </Radio.Group>
          <Row gutter={[4, 4]}>
            <Col xs={24} sm={24} md={12}>
              <Button
                block={true}
                type={"primary"}
                danger={true}
                style={{ marginTop: "10px" }}
                onClick={handleComplainClaimable}
              >
                이의건
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Button
                block={true}
                style={{ marginTop: "10px", backgroundColor: "darkgrey" }}
                onClick={handlelIsTowEditModalVisible}
              >
                견인건
              </Button>
            </Col>
          </Row>
        </Card>
        {complain.towReceiptImage != null ? (
          <Row>
            <Col sm={24} md={24}>
              <Card
                style={{ width: "100%" }}
                cover={
                  <Image
                    alt="example"
                    src={complain.towReceiptImage}
                    width={"70%"}
                  />
                }
                title="견인 영수증"
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col sm={24} md={12}>
            {complainImageTags}
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={{ width: "100%" }}
              cover={
                <Image
                  alt="example"
                  src={complain.rideImages[0]}
                  width={"70%"}
                />
              }
              title="반납사진"
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return <></>;
  }
}
