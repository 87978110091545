// export const ADMIN_API_URL = "http://localhost:8080";
export const ADMIN_API_URL = "https://api.complain.swingmobility.dev"
export const ADMIN_WEB_URL = "https://adminweb.theswing.co.kr"

export const complainStates = [
  {eng: "COMPLAINED", kor: "신고됨"},
  {eng: "COMPLETED", kor: "처리됨"},
  {eng: "MIS", kor: "오신고"},
  {eng: "COLLECTED", kor: "수거됨"},
  {eng: "TOWED", kor: "견인됨"},
  {eng: "isClaimable", kor: "이의건"}
]