import { AdminApi } from '.';

export async function getAllComplains() {
  return await AdminApi.get<ComplainDto[]>(`/complains`).then((resp) => resp.data);
}

export async function getComplain(id: string) {
  return await AdminApi.get<ComplainDto>(`/complains/${id}`).then((resp) => resp.data);
}

export async function putComplainClaimable(id: string, content: {isClaimable: boolean}) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}

export async function putComplainState(id: string, content: {state: string}) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}

export async function putComplain(id: string, content: {state: string}) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}

export async function putComplainTowReceipt(id: string, content: ComplainUpdateTowReceiptDto) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}

export async function postComplain(content: NewComplainDto) {
  return await AdminApi.post<ComplainDto>(`/complains`, content).then((resp) => resp.data);
}

export async function postImposeWarning(id: number, content: ComplainUpdateDto) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}

export async function putComplainIsComplainable(id: number, content: ComplainUpdateDto) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}

export async function putComplainIsTowed(id: string, content: {towedAt: number}) {
  return await AdminApi.put<ComplainDto>(`/complains/${id}`, content).then((resp) => resp.data);
}
