import React, {useState, useEffect} from 'react';
import {Table, Badge} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {getDateTimeShort, getComplainStateTag} from "../../utils/organizeData";


interface Filter {
  text: string
  value: string
}

export function ComplainListTable({complains}: { complains: ComplainListDto[] }) {

  const [provinceFilters, setProvinceFilters] = useState<Filter[]>([]);

  useEffect(() => {
    const provinceFilterNameSet = new Set<string>();
    for (const complain of complains) {
      provinceFilterNameSet.add(complain.province);
    }

    setProvinceFilters(Array.from(provinceFilterNameSet).map<Filter>(name => ({
      text: name,
      value: name
    })));
  }, [complains]);

  const complainListColumn: ColumnsType<ComplainListDto> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: '민원 ID',
      align: 'center',
      fixed: 'left',
      width: '5%',
      render: (val, row) => (
        <Badge
          status={row.isClaimable ? 'error' : 'success'}
          dot={true}
          offset={[8, 5]}
        >
          <a
            target={"_blank"}
            href={`/complain/${val}`}
            style={{cursor: 'pointer', fontWeight: 'bold'}}
          >{val}</a>
        </Badge>
      )
    },
    {
      key: 'complainAt',
      title: '민원접수시간',
      align: 'center',
      width: '6%',
      render: (val, row) => {
        return getDateTimeShort(row.complainAt)
      }
    },
    {
      key: 'shortImei',
      dataIndex: 'shortImei',
      title: '킥보드번호',
      align: 'center',
      width: '6%',
    },
    {
      key: 'province',
      dataIndex: 'province',
      title: '민원지역',
      align: 'center',
      filters: provinceFilters,
      onFilter: (value, record) => record.province === value,
      width: '6%',
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: '상세주소',
      align: 'center',
      width: '25%',
    },
    {
      key: 'isTowed',
      dataIndex: 'state',
      title: '민원상태',
      align: 'center',
      width: '5%',
      filters: [
        {text: '신고됨', value: "COMPLAINED"},
        {text: '견인됨', value: "TOWED"},
        {text: '수거됨', value: "COLLECTED"},
        {text: '오신고', value: "MIS"},
        {text: '처리됨', value: "COMPLETED"},
      ],
      onFilter: (value, record) => record.state === value,
      render: (val, row) => {
        return getComplainStateTag(val)
      }
    },
    {
      key: 'complainFrom',
      dataIndex: 'complainFrom',
      title: '민원신고채널',
      align: 'center',
      width: '8%',
    }
  ];

  return (
    <Table
      dataSource={complains.map((e, i) => ({
        key: 'complainList' + i,
        ...e
      }))}
      columns={complainListColumn}
      bordered={true}
      pagination={{
        pageSize: 100,
      }}
      scroll={{x: 1500}}
    />
  );
}
