import {Tag} from "antd";
import React from "react";
import moment from 'moment'

const FIVEMINUTES = 300000;
const TENMINUTES = 600000;
const FIFTEENMINUTES = 900000;
const THIRTYMINUTES = 1800000;
const ONEHOUR = 3600000;
const TWOHOURS = 7200000;
const THREEHOURS = 10800000;

export const getDateTime = (val: number) => {
  const date = new Date(val + (9000 * 60 * 60)).toISOString().split('T');
  return date[0] + " " + (date[1].split('.'))[0]
}

export const getDateTimeShort = (val: number) => {
  const diff = (new Date().getTime()) - val;
  if (diff <= FIVEMINUTES) {
    return '5분전'
  } else if (diff > FIVEMINUTES && diff <= TENMINUTES) {
    return '10분전'
  } else if (diff > TENMINUTES && diff <= FIFTEENMINUTES) {
    return '15분전'
  } else if (diff > FIFTEENMINUTES && diff <= THIRTYMINUTES) {
    return '30분전'
  } else if (diff > THIRTYMINUTES && diff <= ONEHOUR) {
    return '1시간전'
  } else if (diff > ONEHOUR && diff <= TWOHOURS) {
    return '2시간전'
  } else if (diff > TWOHOURS && diff <= THREEHOURS) {
    return '3시간전'
  } else {
    const koreanTimezoneVal = val + 32400000;
    const [yearMonthDay, hourMintueSecond] = new Date(koreanTimezoneVal).toISOString().split('T');
    return (<span>{yearMonthDay.split('-').join('.')}<br/>{hourMintueSecond.split(":").slice(0, 2).join(':')}</span>)
  }
}

export const getDateTimeLong = (val: number) => {
  const koreanTimezoneVal = val + 32400000;
  const [yearMonthDay, hourMintueSecond] = new Date(koreanTimezoneVal).toISOString().split('T');
  return (<span>{yearMonthDay.split('-').join('.')} {hourMintueSecond.split(":").slice(0, 2).join(':')}</span>)
}

export const getComplainStateTag = (val: string) => {

  let complainStateTag = <></>;

  switch(val) {
    case "COMPLAINED":
      complainStateTag = <Tag color="orange">신고됨</Tag>
      break;
    case "COMPLETED":
      complainStateTag = <Tag color="blue">처리됨</Tag>
      break;
    case "TOWED":
      complainStateTag = <Tag color="magenta">견인됨</Tag>
      break;
    case "COLLECTED":
      complainStateTag = <Tag color="geekblue">수거됨</Tag>
      break;
    case "MIS":
      complainStateTag = <Tag color="green">오신고</Tag>
      break;
    case "isClaimable":
      complainStateTag = <Tag color="black">이의건</Tag>
      break;
  }

  return complainStateTag
}