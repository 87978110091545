import React, {useState} from 'react';
import {BaseModal} from '../bases/BaseModal';
import {Input, Form, Radio, Row, Col} from 'antd';
import {postComplain} from "../../services/complain";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editComplain?: ComplainDto | null;
}

export function AddComplainModal(props: Props) {

  const [formValues, setFormValues] = useState<NewComplainDto | null>(null);
  const [formInstance] = Form.useForm();

  const onSubmitForm = async (formValues: NewComplainDto) => {
    const newComplainDto: NewComplainDto = {
      shortImei : formValues.shortImei,
      state : 'COMPLAINED',
      address : formValues.address,
      complainFrom : formValues.complainFrom,
      reason : formValues.reason,
      province : formValues.province
    };

    console.log(newComplainDto);
    if (window.confirm("정말 등록하시겠습니까?")) {
      try {
        await postComplain(newComplainDto).then(e => {
          alert("성공적으로 등록 되었습니다");
          window.location.reload();
        })
      } catch (err) {
        alert("등록에 실패하였습니다 : " + err);
        window.location.reload();
      }
    }
  }

  const submitModal = async () => {
    formInstance.submit();
  }

  return (
    <BaseModal
      isVisible={props.isVisible}
      handleOk={submitModal}
      handleCancel={() => {
        props.setIsVisible(false);
      }}
      headerTitle={'민원 등록'}
      width={'70%'}
    >
      <Form
        name="addComplainForm"
        onValuesChange={(changedVal, allVal) => {
          setFormValues(allVal);
        }}
        form={formInstance}
        onFinish={onSubmitForm}
      >
        <Row
          gutter={[16, 32]}
          justify={'center'}
        >
          <Col xs={24}>
            <Form.Item
              label="민원유입"
              name="complainFrom"
              rules={[{required: true}]}
              style={{width: '100%'}}
            >
              <Radio.Group
                buttonStyle="solid"
                optionType={'button'}
                style={{width: '100%'}}
              >
                <Radio.Button value={'cs'} style={{width: '50%', textAlign:'center'}}>CS</Radio.Button>
                <Radio.Button value={'kakaotalk'} style={{width: '50%', textAlign:'center'}}>카카오톡</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="킥보드번호" name="shortImei" rules={[{required: true}]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="민원등록자" name="assignee" rules={[{required: true}]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="민원지역" name="province" rules={[{required: true}]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="민원주소" name="address" rules={[{required: true}]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24}>
            <Form.Item label="민원사유" name="reason" rules={[{required: true}]}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
}
